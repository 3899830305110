//*// VIEWS //*//

//standard view
//table view inherited form tables & tumbnail view on thumbs.plone.less
.folder-listing {
	font-weight: @plone-font-weight-bold;
	list-style: none;
	position: relative;
	padding-left: 20px;
	//need to add a list portlets-like bullet
	li {margin: 10px 0;}
	> li:before {
			content:"•"; position: absolute; font-size: @plone-font-size-large+1; margin-top: -9px; left: 0;
			color: lighten(@plone-portlet-list-bullet, 15%);
	}
	.documentByLine {font-weight: @plone-font-weight-regular; color: @plone-gray-light;}
}

//summary view
.tileImage { padding-right: 5px; float:left; }
.tileImageNoFloat { margin-bottom: 5px; }
.tileFooter { clear: both; margin-top: 5px; }
