//*// FONTS //*//

//*// Plone base font
// <link href='http://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,500,500italic|Roboto+Condensed:300italic,300' rel='stylesheet' type='text/css'>

//*// Standard
// 100
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 100;
	src: local('Roboto Thin'), local('Roboto-Thin'), url("roboto/Roboto-Thin.ttf") format('truetype');
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 100;
	src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url("roboto/Roboto-ThinItalic.ttf") format('truetype');
}

// 300
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Light'), local('Roboto-Light'), url("roboto/Roboto-Light.ttf") format('truetype');
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 300;
	src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url("roboto/Roboto-LightItalic.ttf") format('truetype');
}

// 500
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'), local('Roboto-Medium'), url("roboto/Roboto-Medium.ttf") format('truetype');
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 500;
	src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url("roboto/Roboto-MediumItalic.ttf") format('truetype');
}


//*// Condensed (Toolbar)
// 300
@font-face {
	font-family: "Roboto Condensed";
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url("roboto/RobotoCondensed-Light.ttf") format('truetype');
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 300;
	src: local('Roboto Condensed Thin Italic'), local('RobotoCondensed-LightItalic'), url("roboto/RobotoCondensed-LightItalic.ttf") format('truetype');
}
