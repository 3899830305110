//*// SITEMAP //*//

#portal-sitemap {
	list-style: none;
	padding-left:0;
	> li {
		font-size: @plone-font-size-large;
		border-top: @plone-portlet-border;
		padding: @plone-padding-base-vertical 0;
	}

	ul {
		position: relative;
		font-size: @plone-font-size-base;
		padding-left:20px;
		-moz-column-count: 3;
	    -moz-column-gap: 20px;
	    -webkit-column-count: 3;
	    -webkit-column-gap: 20px;
	    column-count: 3;
	    column-gap: 20px;
	    // remember that columns does not work on IE9
	    & li {position: relative;}
	    & li:before {
	    	content:"•"; display: inline; font-size: @plone-font-size-h3+1;
					color: lighten(@plone-portlet-list-bullet, 15%); top: 5px; position:relative;

	    }
	}
}
